import * as React from 'react';
import { verifyUpload, deleteUpload, VerifySuccessResults, postUploads } from 'api/fileUploads';
import UploadArea, { UploadFile } from '../UploadArea/UploadArea';
import FileStatus from '../FileStatus/FileStatus';
import { Status } from 'libs/utils/api/types';
import { FileVersion, LineItem, Upload, Order } from 'api/orders/types';
import { UploadForm } from 'bundles/App/pages/UploadPage/MainSection/MainSection';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import { ProofApproval } from 'bundles/App/pages/ProofApprovalsPage/types';
import toasts from 'utils/toast';
import { type OrderDispatchType } from 'app/contexts/OrderContext/actions';
import ProductDescription from 'styleguide/components/ProductsTable/ProductDescription/ProductDescription';
import cn from 'classnames';

export interface FileData {
  key?: string;
  id?: number;
  name?: string;
  message?: string;
  status?: number;
  url?: string;
  verified?: boolean;
}

interface Props {
  showButtons?: boolean;
  lineItem?: LineItem;
  owner?: FileVersion | ProofApproval;
  ownerType?: string;
  uploadForm?: UploadForm;
  onLoading: () => void;
  onReady: () => void;
  onRemoveUpload?: (dispatch: OrderDispatchType, id: number) => void;
  onAddUpload?: (dispatch: OrderDispatchType, id: number, upload: Upload) => void;
  dispatch?: OrderDispatchType;
  filesFromProps?: Upload[];
  order?: Order;
}

interface FilesData {
  [key: string]: FileData;
}

interface UploadForSignedUrl {
  upload: {
    name: string;
    guestUploadAttributes?: {
      name: string;
      email: string;
      comments: string;
    };
  };
  contentType: string;
  ownerId?: number;
  ownerType?: string;
}

const UploadAreaWithStatus = ({
  showButtons,
  lineItem,
  owner,
  ownerType,
  uploadForm,
  onLoading,
  onReady,
  onRemoveUpload,
  onAddUpload,
  dispatch,
  filesFromProps,
  order,
}: Props) => {
  const [filesData, setFilesData] = React.useState<FilesData>(
    owner
      ? owner.uploads.reduce((acc, upload) => {
          acc[upload.name] = {
            key: upload.id.toString(),
            id: upload.id,
            name: upload.name,
            message: '',
            url: upload.url,
            verified: true,
          };

          return acc;
        }, {})
      : {},
  );

  React.useEffect(() => {
    if (owner) {
      setFilesData(
        owner.uploads.reduce((acc, upload) => {
          acc[upload.name] = {
            key: upload.id.toString(),
            id: upload.id,
            name: upload.name,
            message: '',
            url: upload.url, // TODO: do we need link?
            verified: true,
          };

          return acc;
        }, {}),
      );
    }
  }, [owner]);

  React.useEffect(() => {
    if (filesFromProps) {
      setFilesData(
        filesFromProps.reduce((acc, upload) => {
          acc[upload.name] = {
            key: upload.id.toString(),
            id: upload.id,
            name: upload.name,
            message: '',
            url: upload.url,
            verified: true,
          };

          return acc;
        }, {}),
      );
    }
  }, [filesFromProps]);

  const getSignedUrl = (file: File, callback) => {
    const upload: UploadForSignedUrl = { upload: { name: file.name }, contentType: file.type };
    if (owner) {
      upload.ownerId = owner.id;
      upload.ownerType = ownerType;
    } else if (uploadForm) {
      upload.upload.guestUploadAttributes = {
        name: uploadForm.fullName,
        email: uploadForm.email,
        comments: uploadForm.comments,
      };
    }

    postUploads(upload).then(res => {
      if (res.status === Status.Ok) {
        callback(res.payload);
      }
    });
  };

  const preprocess = (file: File, next: (preProcessedFile: File) => void) => {
    onLoading();
    next(file);
  };

  const onProgress = (percent: number, status: string, file: File) => {
    const newFilesData = { ...filesData };
    if (newFilesData[file.name] === undefined) {
      newFilesData[file.name] = {};
    }
    newFilesData[file.name].message = status;
    newFilesData[file.name].status = percent;
    setFilesData(newFilesData);
    onReady();
  };

  const onFilesDrop = (files: File[]): File[] => {
    let filteredFiles = [...files];
    files.forEach(file => {
      if (filesData[file.name]) {
        filteredFiles = [...filteredFiles.filter(f => f.name !== file.name)];
        toasts.create(`the file "${file.name}" already exists. please rename your file`, 'error');
      }
    });

    return filteredFiles;
  };

  const onFinish = (wrappedFile: { file: UploadFile; uploadId: number }) => {
    if (!wrappedFile.uploadId) {
      throw Error(`${wrappedFile.file.name} has no uploadId.`);
    }

    const handleSuccess = (res: VerifySuccessResults) => {
      onAddUpload(dispatch, owner?.id, {
        name: wrappedFile.file.name,
        id: wrappedFile.uploadId,
        url: res.url,
        createdAt: res.createdAt,
      });
    };

    verifyUpload(wrappedFile.uploadId).then(res => {
      const newFilesData = { ...filesData };

      if (res.status === Status.Ok) {
        newFilesData[wrappedFile.file.name].id = res.payload.id;
        handleSuccess(res.payload);
      }
      newFilesData[wrappedFile.file.name].verified = res.status === Status.Ok;
      setFilesData(newFilesData);
      onReady();
    });
  };

  const onError = (status: Error, file: File) => {
    const newFilesData = { ...filesData };
    newFilesData[file.name].message = 'Error';
    setFilesData(newFilesData);
    onReady();
  };

  const removeFile = (key: string) => {
    if (filesData[key].id) {
      onLoading();
      deleteUpload(filesData[key].id).then(res => {
        if (res.status === Status.Ok) {
          onRemoveUpload(dispatch, filesData[key].id);
        }
      });
    }
    const newFilesData = { ...filesData };
    delete newFilesData[key];
    setFilesData(newFilesData);
    onReady();
  };

  const getItemsArray = (items: FilesData) =>
    Object.keys(items).map(key => {
      const item = {
        key,
        name: key.split('~')[0],
        status: items[key].status,
        url: items[key].url,
        verified: items[key].verified,
        message: items[key].message,
      };
      return item;
    });

  return (
    <>
      <div className="flex -md:flex-col-reverse w-full">
        <div className={cn('w-full md:mb-1 md:mr-6', lineItem && 'md:w-1/2')}>
          <UploadArea
            preprocess={preprocess}
            onProgress={onProgress}
            onFilesDrop={onFilesDrop}
            onFinish={onFinish}
            onError={onError}
            getSignedUrl={getSignedUrl}
          />
        </div>
        <div className="md:hidden">
          <FileStatus removeFile={removeFile} filesInUpload={getItemsArray(filesData)} lineItem={lineItem} />
        </div>
        <div className="w-full md:w-1/2">
          {lineItem && <ProductDescription order={order} lineItem={lineItem} withBackground />}
        </div>
      </div>
      <div className="-md:hidden">
        <FileStatus removeFile={removeFile} filesInUpload={getItemsArray(filesData)} lineItem={lineItem} />
      </div>
      {!!showButtons && (
        <div>
          <ButtonAsNativeLink buttonType="link" color="orange" target="/">
            Go to Homepage
          </ButtonAsNativeLink>
        </div>
      )}
    </>
  );
};

export default UploadAreaWithStatus;
