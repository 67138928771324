import * as React from 'react';
import cn from 'classnames';
import DropZoneS3Uploader from './dropzone-s3-uploader';
import { IconUpload } from 'styleguide/icons';
import IfMobile from 'styleguide/components/Conditional/IfMobile';

export interface UploadFile extends File {
  uploadId?: number | null;
  filename?: string;
}

interface Props {
  onFilesDrop?: (acceptedFiles: File[]) => File[];
  getSignedUrl?: (file: File, callback) => void;
  preprocess?: (file: File, next: (preProcessedFile: File) => void) => void;
  onProgress?: (percent: number, status: string, file: File) => void;
  onFinish?: (wrappedFile: { file: UploadFile; uploadId: number }) => void;
  onError?: (status: Error, file: File) => void;
}

const UploadArea = ({ onFilesDrop, getSignedUrl, preprocess, onProgress, onFinish, onError }: Props) => {
  const onDrop = (acceptedFiles: File[]) => {
    if (onFilesDrop) {
      return onFilesDrop(acceptedFiles);
    }
    return [];
  };

  return (
    <DropZoneS3Uploader
      s3Url="https:default_dev_mgxcopy_uploads.s3.amazonaws.com"
      onDrop={onDrop}
      onProgress={onProgress}
      onFinish={onFinish}
      onError={onError}
      upload={{ preprocess, getSignedUrl }}
      passChildrenProps={false}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          data-cy="dropzoneUploadZone"
          className={cn(
            `w-full h-full min-h-[240px] flex flex-col justify-center items-center text-center cursor-pointer px-3 border-[1.5px]
            border-[#c0c0c0] border-dashed
            -md:flex-row -md:justify-between -md:border-solid -md:min-h-[75px] -md:pl-8
            hover:border-blue-500 hover:bg-blue-50 hover:border-solid`,
            isDragActive && 'border-blue-500 bg-blue-50 border-[1.5px]',
            '-md:mt-6 -md:!min-h-[44px] -md:rounded-lg -md:border -md:!border-blue -md:!pl-0',
          )}
        >
          <input {...getInputProps()} />
          <IconUpload
            className="-md:w-11 -md:h-10 !hidden md:!flex md:justify-center md:max-h-5"
            size="xxl"
          />
          <IfMobile>
            <div className="font-hvMedium text-blue">Select Files</div>
          </IfMobile>
          <div data-cy="uploadAreaBrowseBtn" className="!hidden md:!flex md:justify-center md:max-h-5">
            {isDragActive ? (
              <p className="text-sm m-0 md:mt-2">Drop file(s) here to upload...</p>
            ) : (
              <p className="text-sm m-0 md:mt-2">
                Drag files to upload complete designs or <u>browse</u>
              </p>
            )}
          </div>
          <div className="mt-10 text-xs -md:w-1/3 -md:mt-0">5GB max filesize</div>
        </div>
      )}
    </DropZoneS3Uploader>
  );
};

export default UploadArea;
